import {LogoOnSiteSimple} from '@hconnect/uikit'
import {Box, Typography, makeStyles} from '@material-ui/core'
import React from 'react'

const useStyle = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    backgroundColor: theme.palette.background,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 16
  },
  logo: {
    maxWidth: 80
  },
  text: {
    paddingTop: 20,
    ...theme.typography.normalText,
    textAlign: 'center',
    whiteSpace: 'pre-wrap'
  }
}))

interface ErrorPageProps {
  errorDescription: string
}
export const ErrorPage: React.FC<ErrorPageProps> = ({errorDescription}) => {
  const classes = useStyle()
  return (
    <Box className={classes.container}>
      <img className={classes.logo} src={LogoOnSiteSimple} alt="" />
      <Typography className={classes.text}>{errorDescription}</Typography>
    </Box>
  )
}
