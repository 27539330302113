import schedule_be_fr from '../assets/schedule_view_be_fr.png'
import schedule_en_default from '../assets/schedule_view_eng.png'
import schedule_dutch from '../assets/schedule_view_dutch.png'
import schedule_de from '../assets/schedule_view_ger.png'
import schedule_nam from '../assets/schedule_view_nam.png'
import schedule_pl from '../assets/schedule_view_pol.png'
import map_be_fr from '../assets/map_view_be_fr.png'
import map_dutch from '../assets/map_view_dutch.png'
import map_de from '../assets/map_view_ger.png'
import map_en_default from '../assets/map_view_eng.png'
import map_nam from '../assets/map_view_nam.png'
import map_pl from '../assets/map_view_pol.png'

export const useOverviewScreenShots = (country: string, language: string) => {
  const defaultScreenshots = [schedule_en_default, map_en_default]
  const screenshotsDutch = [schedule_dutch, map_dutch]
  const namScreenshots = [schedule_nam, map_nam]

  const contrySreenshotMap = {
    DE: [schedule_de, map_de],
    BE_FR: [schedule_be_fr, map_be_fr],
    BE_NL: screenshotsDutch,
    US: namScreenshots,
    CA: namScreenshots,
    PL: [schedule_pl, map_pl],
    NL_NL: screenshotsDutch
  }
  const screenshots = contrySreenshotMap[country] || contrySreenshotMap[`${country}_${language}`]
  return screenshots || defaultScreenshots
}
