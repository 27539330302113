import {AppStoreBadge, Banner, GooglePlayBadge, Grid, LogoOnSiteFooter} from '@hconnect/uikit'
import {Box, Link, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

import {isCookieBannerNotRequired, recordAndOpen} from '../../common/analytics'
import {APP_STORE_LINK, GOOGLE_PLAY_LINK} from '../../common/constants'
import type {Order} from '../../common/interfaces'
import {OnSiteTheme} from '../../common/OnSiteTheme'
import {getConfig} from '../../common/oreoSettings'
import {useOnSiteTranslation} from '../../common/useOnSiteTranslation'
import {getUrlParameterValue} from '../../common/utils'

const useStyle = makeStyles((theme: any) => ({
  container: {
    backgroundColor: theme.palette.backgroundDark,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: 16
  },
  logoOnSiteBotton: {
    maxWidth: 118
  },
  textlogoOnSiteBotton: {
    fontSize: 12,
    padding: 6
  },
  storeButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  storeButtonsAppStoreIcon: {
    marginLeft: 16,
    marginRight: 6,
    maxHeight: 80
  },
  storeButtonsPlayStoreIcon: {
    marginRight: 16,
    marginLeft: 6,
    maxHeight: 60
  }
}))

interface OrderFooterProps {
  order?: Order
}
// TODO: Cookie page should close after settings is open
export const OrderFooter: React.FC<OrderFooterProps> = ({order}) => {
  const [openCookies, setOpenCookies] = React.useState(false)

  const {t} = useOnSiteTranslation()
  const classes = useStyle()
  const countryCode = getUrlParameterValue('countryCode')
  const showCookieLink: boolean =
    !countryCode || (!!countryCode && !isCookieBannerNotRequired(countryCode))

  return (
    <Grid container className={classes.container}>
      {!!openCookies && (
        <Banner
          labels={getConfig(t).bannerLabels}
          policyInformation1={getConfig(t).policyInformation1}
          policyInformation2={getConfig(t).policyInformation2}
          topics={getConfig(t).topics}
          theme={OnSiteTheme}
          onClose={() => setOpenCookies(false)}
        />
      )}
      <Box className={classes.logoContainer}>
        <img className={classes.logoOnSiteBotton} src={LogoOnSiteFooter} alt="" />
        <Typography className={classes.textlogoOnSiteBotton}>
          {t('orderCard.footer.logoText')}
        </Typography>
        {showCookieLink && (
          <>
            <Typography className={classes.textlogoOnSiteBotton}>
              <Link
                href="#"
                onClick={() => {
                  setOpenCookies(true)
                }}
              >
                Cookies
              </Link>
            </Typography>
          </>
        )}
      </Box>
      <Box className={classes.storeButtons}>
        <Link
          href="#"
          onClick={() => recordAndOpen('previewOnAppStoreFooterLinkClicked', APP_STORE_LINK, order)}
          variant="inherit"
        >
          <img
            className={classes.storeButtonsAppStoreIcon}
            src={AppStoreBadge}
            alt="iOS AppStore"
          />
        </Link>
        <Link
          href="#"
          onClick={() =>
            recordAndOpen('previewOnPlayStoreFooterLinkClicked', GOOGLE_PLAY_LINK, order)
          }
          variant="inherit"
        >
          <img
            className={classes.storeButtonsPlayStoreIcon}
            src={GooglePlayBadge}
            alt="Google Play Store"
          />
        </Link>
      </Box>
    </Grid>
  )
}
