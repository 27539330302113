import IsoIcon from '@material-ui/icons/Iso'
import React from 'react'

import type {Order} from '../../common/interfaces'
import {useOnSiteTranslation} from '../../common/useOnSiteTranslation'
import {OrderCardItem} from '../OrderCardItem'

interface OrderCardMixProps {
  order: Order
}

export const OrderCardMix: React.FC<OrderCardMixProps> = ({order}) => {
  const {supplementItems, materialNumber, materialDescription, customerMaterialDescription} = order
  const orderSupplements = (supplementItems || []).filter((supplement) => {
    const {relatedItemNumber, itemNumber} = supplement
    return itemNumber === relatedItemNumber
  })

  const {t} = useOnSiteTranslation()

  const getExtraInformation = (orderSupplement): string | null => {
    if (!orderSupplement || !Array.isArray(orderSupplement) || orderSupplement.length === 0) {
      return null
    }
    if (orderSupplement.length === 1) {
      return `+ ${orderSupplement.length} ${t('order.extras.singular')}`
    }
    return `+ ${orderSupplement.length} ${t('order.extras.plural')}`
  }

  const mixProperty: string =
    materialDescription || materialNumber || customerMaterialDescription || ''

  const orderCardProps = {
    key: 'mix',
    iconComponent: <IsoIcon />,
    information: mixProperty,
    testIDIcon: 'order-card-mix-icon',
    testIDText: 'order-card-mix-value'
  }
  const extras = getExtraInformation(orderSupplements)
  if (extras) {
    orderCardProps[extras] = extras
  }

  return <OrderCardItem {...orderCardProps} />
}
