import React from 'react'

import {useOnSiteTranslation} from '../../common/useOnSiteTranslation'
import {OrderOverviewTab} from '../OrderOverviewTab'

interface OrderScheduleTabProps {
  screenshot: string
}

export const OrderScheduleTab: React.FC<OrderScheduleTabProps> = ({screenshot}) => {
  const {t} = useOnSiteTranslation()
  return <OrderOverviewTab description={t('scheduleTab.description')} screenshot={screenshot} />
}
