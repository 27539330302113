import {CircularProgress, createTheme, CssBaseline, ThemeProvider} from '@material-ui/core'
import axios, {AxiosInstance} from 'axios'
import React, {useEffect, useMemo, useState} from 'react'
import {HelmetProvider} from 'react-helmet-async'

import {OnSiteTheme} from './common/OnSiteTheme'
import {getGlobalVars} from './index.global'
import {Localization} from './locales'

interface ProviderProps {
  children: React.ReactNode
}

export interface StateContext {
  publicApi: AxiosInstance
}

export const ContextState = React.createContext<StateContext>({
  publicApi: axios.create()
})

export const useGlobal = () => React.useContext(ContextState)

const modifiedTheme = createTheme({
  ...OnSiteTheme,
  palette: {
    ...OnSiteTheme.palette,
    text: {
      ...OnSiteTheme.palette.text,
      secondary: '#628390'
    }
  }
})

/* istanbul ignore next */
export const Provider = ({children}: ProviderProps) => {
  const [content, setContent] = useState(<CircularProgress />)

  // create global variables exactly once
  const {publicApi} = useMemo(() => getGlobalVars(), [])
  useEffect(() => {
    Localization()
      .then(() => {
        setContent(<>{children}</>)
      })
      .catch((error) => console.error(error))
  }, [children])

  return (
    <HelmetProvider>
      <meta name="format-detection" content="telephone=yes" />
      <style />
      <ContextState.Provider
        value={{
          publicApi
        }}
      >
        <ThemeProvider theme={modifiedTheme}>
          <CssBaseline />
          {content}
        </ThemeProvider>
      </ContextState.Provider>
    </HelmetProvider>
  )
}
