import {STATISTICS_COOKIE_TOPIC_ID, NECESSARY_COOKIE_TOPIC_ID} from '@hconnect/uikit'

export const getConfig = (t) => {
  const bannerLabels = {
    title: t('cookie.title'),
    description: t('cookie.description'),
    acceptButton: t('cookie.acceptButton'),
    acceptSelectionButton: t('cookie.acceptSelectionButton'),
    settingsButton: t('cookie.settingsButton'),
    providerLabel: t('cookie.provider')
  }
  const policyInformation1 = t('cookie.policyInformation1')
  const policyInformation2 = t('cookie.policyInformation2')

  const topics = [
    {
      id: NECESSARY_COOKIE_TOPIC_ID,
      title: t('cookie.topics.necessary.title'),
      description: t('cookie.topics.necessary.description'),
      cookies: [
        {
          id: 'hconnect-cookie',
          title: t('cookie.cookies.hconnect-cookie.title'),
          description: t('cookie.cookies.hconnect-cookie.description'),
          provider: t('cookie.cookies.hconnect-cookie.provider')
        },
        {
          id: 'i18next',
          title: t('cookie.cookies.i18next.title'),
          description: t('cookie.cookies.i18next.description'),
          provider: t('cookie.cookies.i18next.provider')
        }
      ],
      preSelected: true,
      readOnly: true
    },
    {
      id: STATISTICS_COOKIE_TOPIC_ID,
      title: t('cookie.topics.statistic.title'),
      description: t('cookie.topics.statistic.description'),
      cookies: [
        {
          id: 'onsite-token',
          title: t('cookie.cookies.onsite-token.title'),
          description: t('cookie.cookies.onsite-token.description'),
          provider: t('cookie.cookies.onsite-token.provider')
        },
        {
          id: 'app-insights',
          title: t('cookie.cookies.app-insights.title'),
          description: t('cookie.cookies.app-insights.description'),
          provider: t('cookie.cookies.app-insights.provider')
        }
      ],
      preSelected: true
    }
  ]

  return {
    bannerLabels,
    policyInformation1,
    policyInformation2,
    topics
  }
}
