import React from 'react'
import {StyleSheet, Text, View} from 'react-native-web'

import {Colors} from '../../common/constants'
import type {Order} from '../../common/interfaces'
import {useOnSiteTranslation} from '../../common/useOnSiteTranslation'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 10
  },
  customerName: {
    fontFamily: 'Roboto',
    color: Colors.TealDark,
    fontWeight: 800,
    paddingRight: 2
  },
  sharingInformation: {
    fontFamily: 'Roboto',
    textAlign: 'center',
    color: Colors.TealDark
  }
})

interface OrderDetailsProps {
  order: Order
}
export const OrderDetails: React.FC<OrderDetailsProps> = ({order}) => {
  const customerName =
    order && order.customer && order.customer.customerName
      ? order.customer.customerName
      : order.customer.customerId
  const {t} = useOnSiteTranslation()

  return (
    <View style={styles.container}>
      <Text style={styles.customerName} testID="order-details-customer-name">
        {customerName}
      </Text>
      <Text style={styles.sharingInformation} testID="order-details-sharing-information">
        {t('orderCard.sharingInformation')}
      </Text>
    </View>
  )
}
