import {
  BackendConfigList,
  BrowserBackendSelector,
  createPublicHttpClient,
  getDefaultDFBackends,
  getPRODBackend,
  getQABackend,
  getUATBackend,
  getUATReadonlyBackend
} from '@hconnect/apiclient'
import {AxiosInstance} from 'axios'

import {getUrlParameterValue} from './common/utils'

export interface GlobalVars {
  publicApi: AxiosInstance
}

const getcustomBackend = (getDefaultBackends: () => BackendConfigList) => {
  const customBackend = getUrlParameterValue('customBackend')

  switch (customBackend) {
    case 'qa':
      return getQABackend()

    case 'uat':
      return getUATBackend()

    case 'uatr':
      return getUATReadonlyBackend()

    case 'prod':
      return getPRODBackend()

    default:
      return getDefaultBackends()
  }
}

export const getGlobalVars = (): GlobalVars => {
  const defaultBackends = getcustomBackend(getDefaultDFBackends)
  const publicApi = createPublicHttpClient({
    backendSelector: new BrowserBackendSelector(defaultBackends)
  })

  return {publicApi}
}
