import {nanoid} from 'nanoid'

const OREO_LOCAL_STORAGE_CLIENT_ID_KEY = `HC-${process.env.REACT_APP_CLIENT_NAME}-oreo:clientId`

export const generateAndStoreClientId = (): string => {
  const uniqueId = nanoid()
  localStorage.setItem(OREO_LOCAL_STORAGE_CLIENT_ID_KEY, uniqueId)
  return uniqueId
}

export const getClientId = (): string =>
  localStorage.getItem(OREO_LOCAL_STORAGE_CLIENT_ID_KEY) || generateAndStoreClientId()
