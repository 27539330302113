import get from 'lodash/get'
import trim from 'lodash/trim'
import moment, {Moment} from 'moment'

import {
  APIOrderTypes,
  APP_STORE_LINK,
  FALLBACK_LINK,
  GOOGLE_PLAY_LINK,
  OrderTypes
} from './constants'
import type {Eta, Order} from './interfaces'

interface OrderCardTimeFormat {
  orderCardTruckTime: string
  orderCardTruckTimeAbbreviation: string
}

export const splitNumbersAndWordsFromTime = (date: string): OrderCardTimeFormat => {
  const parts = date.match(/[a-z]+|[^a-z]+/gi)
  return {
    orderCardTruckTime: trim(parts?.[0]),
    orderCardTruckTimeAbbreviation: trim(parts?.[1])
  }
}

export const getTruckTimeObject = (orderDate: string): OrderCardTimeFormat => {
  const truckTime: string = moment(orderDate).format('LT').toLowerCase() // e.g. 4:18 pm
  return splitNumbersAndWordsFromTime(truckTime)
}

export const isTruckArrivingInLessThanOneHour = (truckTime: Moment): boolean => {
  const nowMoment = moment()
  const timeUnit = 'minutes'
  const truckDurationInMinutes = truckTime.diff(nowMoment, timeUnit)
  return truckDurationInMinutes < 60
}

export const getInTransitQuantity = (order: Order): number => {
  const {deliveryProgress} = order
  const {shippedQuantity, arrivedQuantity} = deliveryProgress
  return shippedQuantity - arrivedQuantity
}

export const getOnSiteQuantity = (order: Order): number => {
  const {deliveryProgress} = order
  const {arrivedQuantity, deliveredQuantity} = deliveryProgress
  return arrivedQuantity - deliveredQuantity
}

export const getPouredQuantity = (order: Order): number => {
  const {deliveryProgress} = order
  const {deliveredQuantity} = deliveryProgress
  return deliveredQuantity
}

export const formatQuantity = (quantity: number): string => {
  const hasAtLeast2Decimals = Math.round((quantity * 100) % 10) !== 0
  if (hasAtLeast2Decimals) {
    return quantity.toFixed(2)
  }

  const hasAtLeast1Decimal = Math.round((quantity * 10) % 10) !== 0
  if (hasAtLeast1Decimal) {
    return quantity.toFixed(1)
  }

  return quantity.toFixed(0)
}

export const getRemainingQuantity = (order: Order): number => {
  const {materialQuantity} = order
  const pouredQuantity = getPouredQuantity(order)
  const onSiteQuantity = getOnSiteQuantity(order)
  const inTransitQuantity = getInTransitQuantity(order)

  const remainingQuantity = materialQuantity - pouredQuantity - onSiteQuantity - inTransitQuantity

  return Math.max(0, remainingQuantity)
}

export const convertSpacingInformation = (rawValue?: string) =>
  Math.round(moment.duration(rawValue).asMinutes())

export const getStatusQuantities = (order: Order) => {
  const pouredQuantity = getPouredQuantity(order)
  const onSiteQuantity = getOnSiteQuantity(order)
  const inTransitQuantity = getInTransitQuantity(order)
  const remainingQuantity = getRemainingQuantity(order)

  return {pouredQuantity, onSiteQuantity, inTransitQuantity, remainingQuantity}
}

export const getStoreLink = (userAgent: string): string => {
  const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !(window as {MSStream?: unknown}).MSStream
  const isAndroid = /android/i.test(userAgent)
  if (isIOS) {
    return APP_STORE_LINK
  }
  if (isAndroid) {
    return GOOGLE_PLAY_LINK
  }
  return FALLBACK_LINK
}

export const getTotalQuantity = (order: Order): number => {
  const {pouredQuantity, onSiteQuantity, inTransitQuantity, remainingQuantity} =
    getStatusQuantities(order)

  return pouredQuantity + onSiteQuantity + inTransitQuantity + remainingQuantity
}

export const getNextTruck = (trucksInTransit: Array<Eta>): Eta | null => {
  if (trucksInTransit.length === 0) {
    return null
  }

  const etaLookupKey = 'estimatedTimeOfArrival'
  return trucksInTransit.reduce((nextTruck, currentTruck) => {
    const nextTruckETA = moment(nextTruck[etaLookupKey])
    const currentTruckETA = moment(currentTruck[etaLookupKey])
    return currentTruckETA.isBefore(nextTruckETA) ? currentTruck : nextTruck
  })
}

export const getBrowserLocale = (): string => {
  const defaultLocale = 'en-GB'
  const locale =
    get(window, 'navigator.language') ||
    get(window, 'navigator.browserLanguage') ||
    get(window, 'navigator.userLanguage') ||
    defaultLocale

  return locale.toLowerCase()
}

export const getBrowserLanguage = (): string => {
  const lang = getBrowserLocale()
  if (lang && lang.length > 2) {
    return lang.slice(0, 2).toLowerCase()
  }
  return lang
}

export const getEstimatedTimeOfArrival = (order: Order): string | undefined => {
  const {eta} = order
  const trucksInTransitJSON = eta.filter((materialLocation) => {
    const {estimatedTimeOfArrival} = materialLocation
    return !!estimatedTimeOfArrival
  })
  const nextTruck = getNextTruck(trucksInTransitJSON)
  if (nextTruck) {
    const {estimatedTimeOfArrival} = nextTruck
    return estimatedTimeOfArrival
  }
  return undefined
}

export const getUrlParameterValue = (parameterName: string): string => {
  parameterName = parameterName.replace(/\[/, '\\[').replace(/]/, '\\]')
  const regex = new RegExp(`[?&]${parameterName}=([^&#]*)`, 'i')
  const results = regex.exec(window.location.search)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

const {
  OrderTypeReserved,
  OrderTypePending,
  OrderTypeConfirmed,
  OrderTypeActive,
  OrderTypeCompleted,
  OrderTypeCancelled,
  OrderTypePaymentPending
} = OrderTypes

export const getOrderType = (orderStatus: string): string => {
  const {
    APIOrderTypeReserved,
    APIOrderTypePending,
    APIOrderTypeConfirmed,
    APIOrderTypeCancelled,
    APIOrderTypeInProgress,
    APIOrderTypePartiallyShipped,
    APIOrderTypeShipped,
    APIOrderTypePartiallyDelivered,
    APIOrderTypeDelivered,
    APIOrderTypePaymentPending
  } = APIOrderTypes

  switch (orderStatus) {
    case APIOrderTypeReserved: {
      return OrderTypeReserved
    }

    case APIOrderTypePending: {
      return OrderTypePending
    }

    case APIOrderTypePaymentPending: {
      return OrderTypePaymentPending
    }

    case APIOrderTypeConfirmed: {
      return OrderTypeConfirmed
    }

    case APIOrderTypePartiallyShipped: {
      return OrderTypeActive
    }

    case APIOrderTypeShipped: {
      return OrderTypeActive
    }

    case APIOrderTypeInProgress: {
      return OrderTypeActive
    }

    case APIOrderTypePartiallyDelivered: {
      return OrderTypeActive
    }

    case APIOrderTypeDelivered: {
      return OrderTypeCompleted
    }

    case APIOrderTypeCancelled: {
      return OrderTypeCancelled
    }

    default: {
      throw new Error(`getOrderType: Unknown orderStatus ${orderStatus}`)
    }
  }
}
