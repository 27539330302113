import {Box, Button, ButtonProps} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import ShareIcon from '@material-ui/icons/Share'
import React from 'react'

const useStyle = makeStyles((theme) => ({
  root: {
    height: 32,
    color: 'white',
    backgroundColor: theme.palette.primary.light,
    border: 0,
    borderRadius: 18,
    ...theme.typography.button,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'rgba(41, 170, 255, .2)'
    }
  },
  buttonIcon: {
    fontSize: 24,
    paddingRight: 7
  }
}))

export interface ShareData {
  title?: string
  text?: string
  url?: string
}

export interface Navigator {
  share?: (data?: ShareData) => Promise<void>
}

interface MobilePrimaryButtonProps {
  props?: any
  shareData?: ShareData
  showIcon?: boolean
  onClick: () => void
  caption: string
}

export const MobilePrimaryButton: React.FC<ButtonProps & MobilePrimaryButtonProps> = ({
  caption,
  onClick,
  shareData,
  showIcon,
  ...props
}) => {
  const classes = useStyle()
  return (
    <Button variant="outlined" disableRipple className={classes.root} {...props} onClick={onClick}>
      {showIcon && <ShareIcon className={classes.buttonIcon} />}
      {caption}
    </Button>
  )
}
