import {getAcceptedCookies} from '@hconnect/uikit'
import {Tags} from '@microsoft/applicationinsights-core-js/src/JavaScriptSDK.Interfaces/ITelemetryItem'
import {ApplicationInsights, DistributedTracingModes} from '@microsoft/applicationinsights-web'
import get from 'lodash/get'

import {getEuMembers} from './constants'
import type {Order} from './interfaces'
import {getClientId} from './storage'
import {getUrlParameterValue} from './utils'

const stage = get(process.env, 'REACT_APP_STAGE')
const STAGE = (stage || 'DEV').toUpperCase()
const connectionString = get(process.env, `REACT_APP_${STAGE}_AZURE_CONNECTION_STRING`)
const appId = get(process.env, `REACT_APP_${STAGE}_AZURE_ID`)

export const config = {
  appId,
  connectionString: connectionString,
  enableAutoRouteTracking: true,
  disableFetchTracking: false,
  enableCorsCorrelation: true,
  disableAjaxTracking: false,
  enableRequestHeaderTracking: true,
  enableResponseHeaderTracking: true,
  isCookieUseDisabled: true,
  isStorageUseDisabled: true,
  enableSessionStorageBuffer: true,
  disableTelemetry: true,
  disableExceptionTracking: true,
  loggingLevelTelemetry: 0,
  distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
  maxBatchSizeInBytes: 0,
  maxBatchInterval: 0
}

export const appInsights = new ApplicationInsights({
  config
})
appInsights.loadAppInsights()
appInsights.addTelemetryInitializer((envelope) => {
  envelope.tags = [
    {
      ...(envelope.tags ?? {}),
      ...{
        'ai.cloud.role': get(process.env, 'REACT_APP_CLIENT_NAME'),
        'ai.cloud.roleInstance': get(process.env, 'REACT_APP_STAGE')
      }
    }
  ] as Tags[]
})

export const getApplicationInsights = (disableTelemetry: boolean) => {
  if (appInsights.config.disableTelemetry !== disableTelemetry) {
    appInsights.config.disableTelemetry = disableTelemetry
    appInsights.config.isCookieUseDisabled = disableTelemetry
    appInsights.config.isStorageUseDisabled = disableTelemetry
    appInsights.config.disableCookiesUsage = disableTelemetry
  }
  return appInsights
}

export const disableTelemetry = (): void => {
  if (!appInsights.config.disableTelemetry) {
    appInsights.config.disableTelemetry = true
    appInsights.config.isCookieUseDisabled = true
    appInsights.config.isStorageUseDisabled = true
    appInsights.config.disableCookiesUsage = true
  }
}

export const enableTelemetry = (): void => {
  if (!appInsights.config.disableTelemetry) {
    appInsights.config.disableTelemetry = false
    appInsights.config.isCookieUseDisabled = false
    appInsights.config.isStorageUseDisabled = false
    appInsights.config.disableCookiesUsage = false
  }
}

interface EventData {
  [key: string]: string
}

export const isCookieBannerNotRequired = (code: string): boolean =>
  !getEuMembers().includes(code.toUpperCase())

export const isTrackingAllowed = (): boolean => {
  if (isCookieBannerNotRequired(getUrlParameterValue('countryCode'))) {
    return true
  }
  const acceptedCookies = getAcceptedCookies()
  if (
    acceptedCookies.acceptedKeys.includes('necessary') &&
    acceptedCookies.acceptedKeys.includes('statistic')
  ) {
    return true
  }
  disableTelemetry()
  return false
}

export const record = (eventName: string, eventData: EventData, order?: Order): void => {
  const webviewType = getUrlParameterValue('webviewType')
  const environment = get(process.env, 'REACT_APP_STAGE')
  if (isTrackingAllowed() && getClientId()) {
    setTimeout(() =>
      getApplicationInsights(false).trackEvent({
        name: eventName,
        properties: {
          ...eventData,
          clientId: getClientId(),
          environment,
          ...(order ? {roleRequestId: get(order, 'roleRequestId')} : {}),
          webviewType
        }
      })
    )
  }
}

export const recordAndOpen = (eventName: string, link: string, order?: Order): void => {
  const eventData = {
    link
  }
  record(eventName, eventData, order)
  const a = window.document.createElement('a')
  a.target = '_blank'
  a.href = link
  a.click()
}
