import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'
import React from 'react'

import {useOnSiteTranslation} from '../../common/useOnSiteTranslation'
import {OrderCardItem} from '../OrderCardItem'

export const OrderCardRMCTestBooked = () => {
  const {t} = useOnSiteTranslation()
  return (
    <OrderCardItem
      key="mix"
      information={t('order.rmc.isTestBooked')}
      iconComponent={<BookmarkBorderIcon />}
    />
  )
}
