import React from 'react'

import {Colors} from '../../common/constants'
import type {Order} from '../../common/interfaces'
import {getStatusQuantities, getTotalQuantity} from '../../common/utils'
import {HCProgressBar} from '../HCProgressBar'

export const getPouredColor = (order: Order): string => {
  const {isHalted} = order

  return isHalted ? Colors.YellowMedium : Colors.GreenMedium
}

export const getOnSiteColor = (order: Order): string => {
  const {isHalted} = order

  return isHalted ? Colors.YellowDark : Colors.GreenDark
}

export const getInTransitColor = (order: Order): string => {
  const {isHalted} = order

  return isHalted ? Colors.YellowInky : Colors.TealInky
}

export const getBackgroundColor = (order: Order): string => {
  const {isHalted} = order

  return isHalted ? Colors.YellowLight : Colors.TealLight
}

interface OrderProgressBarProps {
  order: Order
  showAllDetails: boolean
  progressBarHeight: number
  containerStyle?: Object
}

interface segmentType {
  progressValue: number
  progressColor: string
}

export const OrderProgressBar: React.FC<OrderProgressBarProps> = ({
  order,
  showAllDetails,
  progressBarHeight,
  containerStyle
}) => {
  const {pouredQuantity, onSiteQuantity, inTransitQuantity} = getStatusQuantities(order)
  const totalQuantity = getTotalQuantity(order)

  const backgroundColor = getBackgroundColor(order)
  const pouredColor = getPouredColor(order)
  const onSiteColor = getOnSiteColor(order)
  const inTransitColor = getInTransitColor(order)

  let segments: segmentType[]
  const pouredSegment = {progressValue: pouredQuantity, progressColor: pouredColor}

  if (showAllDetails) {
    segments = [
      pouredSegment,
      {progressValue: onSiteQuantity, progressColor: onSiteColor},
      {progressValue: inTransitQuantity, progressColor: inTransitColor}
    ]
  } else {
    segments = [pouredSegment]
  }
  return (
    <HCProgressBar
      segments={segments}
      totalValue={totalQuantity}
      backgroundColor={backgroundColor}
      barHeight={progressBarHeight}
      containerStyle={containerStyle}
    />
  )
}
