import React from 'react'
import {StyleSheet, Text, View} from 'react-native-web'

import {Colors, Fonts} from '../../common/constants'
import type {Order} from '../../common/interfaces'
import {useOnSiteTranslation} from '../../common/useOnSiteTranslation'
import {convertSpacingInformation} from '../../common/utils'

const styles = StyleSheet.create({
  truckInformationContainer: {
    flexDirection: 'column',
    borderColor: Colors.Gray, // won't be visible when width is 0
    borderTopWidth: 0.5,
    paddingVertical: 13
  },
  truckInformationTitle: {
    color: Colors.TealDark,
    marginBottom: 6,
    ...Fonts.RobotoRegular
  },
  truckInformationBoxContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  truckInformationTimeContainer: {
    flexDirection: 'row',
    flex: 1,
    textAlign: 'left',
    alignItems: 'baseline'
  },
  truckInformationTitleInLeftBoxContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center'
  },
  truckInformation24hourTime: {
    flex: 1
  },
  truckInformationTime: {
    color: Colors.TealDark,
    textAlign: 'left',
    ...Fonts.RobotoLight,
    fontSize: 36
  },
  truckInformationTimeAbb: {
    fontFamily: 'Roboto',
    color: Colors.TealDark,
    textAlign: 'left',
    fontSize: 16,
    paddingLeft: 3,
    paddingBottom: 6
  },
  truckInformationMinuteContainer: {
    flexDirection: 'row',
    flex: 1,
    textAlign: 'center',
    alignItems: 'baseline'
  },
  truckInformationMinutes: {
    flex: 3,
    color: Colors.TealDark,
    textAlign: 'right',
    ...Fonts.RobotoLight,
    fontSize: 36
  },
  truckInformationMinuteAbb: {
    flex: 3,
    ...Fonts.RobotoRegular,
    color: Colors.TealDark,
    textAlign: 'left',
    fontSize: 16,
    paddingLeft: 3,
    paddingBottom: 6
  },
  truckInformationTitleInBox: {
    color: Colors.TealDark,
    textAlign: 'left',
    ...Fonts.RobotoRegular
  },
  truckInformationSeparator: {
    width: 1,
    backgroundColor: Colors.Gray,
    marginHorizontal: 15
  },
  truckInformationSpacingFlowContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    flexDirection: 'column'
  },
  truckInformationSpacingFlow: {
    flexDirection: 'row',
    textAlign: 'left'
  },
  truckInformationSpacingFlowTitle: {
    fontFamily: 'Roboto',
    color: Colors.TealDark,
    fontSize: 14
  },
  truckInformationSpacingFlowValue: {
    flex: 1,
    ...Fonts.RobotoBold,
    color: Colors.TealDark,
    fontSize: 14
  }
})

interface OrderCardTruckInformationProps {
  order: Order
  orderCardTruckTitle: string
  orderCardTruckTime?: string
  orderCardTruckTimeAbbreviation?: string
  orderCardTruckIsMinutes?: boolean
}

export const OrderCardTruckInformation: React.FC<OrderCardTruckInformationProps> = ({
  order: {schedule, materialQuantityUom},
  orderCardTruckTitle,
  orderCardTruckTime,
  orderCardTruckTimeAbbreviation,
  orderCardTruckIsMinutes
}) => {
  const {t} = useOnSiteTranslation()
  const {arrivalSpacing, quantityPerHour} = schedule
  let truckTimeTitleInBox
  if (orderCardTruckTitle && !orderCardTruckTime) {
    truckTimeTitleInBox = orderCardTruckTitle
  }

  const spacingInformation = {
    spacingTitle: t('order.spacingTitle'),
    spacing: t('order.spacing', {arrivalSpacing: convertSpacingInformation(arrivalSpacing)})
  }

  const flowrateInformation = {
    flowrateTitle: t('order.flowrateTitle'),
    flowrate: `${t('order.flowrate', {quantityPerHour, materialQuantityUom})}`
  }

  const getTimeBox = (time: string, abb?: string) => {
    const timeStyles: string = !abb
      ? [styles.truckInformationTime, styles.truckInformation24hourTime]
      : styles.truckInformationTime
    return (
      <View style={styles.truckInformationTimeContainer}>
        <Text style={timeStyles}>{time}</Text>
        {!!abb && <Text style={styles.truckInformationTimeAbb}>{abb}</Text>}
      </View>
    )
  }

  const getMinuteBox = (time, abb) => (
    <View style={styles.truckInformationMinuteContainer}>
      <Text style={styles.truckInformationMinutes}>{time}</Text>
      <Text style={styles.truckInformationMinuteAbb}>{abb}</Text>
    </View>
  )

  const getTitleInBox = (title) => (
    <View style={styles.truckInformationTitleInLeftBoxContainer}>
      <Text style={styles.truckInformationTitleInBox}>{title}</Text>
    </View>
  )

  const {flowrateTitle, flowrate} = flowrateInformation
  const {spacingTitle, spacing} = spacingInformation

  const addSpaceTopOfSpacingFlow = null

  const headInformation =
    !truckTimeTitleInBox && orderCardTruckTitle ? (
      <Text style={styles.truckInformationTitle}>{orderCardTruckTitle}</Text>
    ) : null

  let truckTimeTitleInBoxContainer
  if (truckTimeTitleInBox) {
    truckTimeTitleInBoxContainer = getTitleInBox(truckTimeTitleInBox)
  } else {
    if (orderCardTruckIsMinutes) {
      truckTimeTitleInBoxContainer = getMinuteBox(
        orderCardTruckTime,
        orderCardTruckTimeAbbreviation
      )
    } else if (orderCardTruckTime) {
      truckTimeTitleInBoxContainer = getTimeBox(orderCardTruckTime, orderCardTruckTimeAbbreviation)
    }
  }

  return (
    <View style={styles.truckInformationContainer}>
      {headInformation}
      <View style={styles.truckInformationBoxContainer}>
        {truckTimeTitleInBoxContainer}
        <View style={styles.truckInformationSeparator} />
        <View style={[styles.truckInformationSpacingFlowContainer, addSpaceTopOfSpacingFlow]}>
          {!!arrivalSpacing && (
            <View style={styles.truckInformationSpacingFlow}>
              <Text style={styles.truckInformationSpacingFlowTitle}>{spacingTitle}</Text>
              <Text
                style={styles.truckInformationSpacingFlowValue}
                testID="order-card-spacing-value"
              >
                {spacing}
              </Text>
            </View>
          )}
          {!!quantityPerHour && (
            <View style={styles.truckInformationSpacingFlow}>
              <Text style={styles.truckInformationSpacingFlowTitle}>{flowrateTitle}</Text>
              <Text
                style={styles.truckInformationSpacingFlowValue}
                testID="order-card-flowrate-value"
              >
                {flowrate}
              </Text>
            </View>
          )}
        </View>
      </View>
    </View>
  )
}
