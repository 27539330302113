import {useEffect} from 'react'
import {useRouteMatch} from 'react-router'

import {appInsights, record} from '../../common/analytics'

const links = {
  survey:
    'https://forms.office.com/pages/responsepage.aspx?id=BiSVVyivyEO03qTgb1dHbcz70sXClvBDkdTj5YDrB1NURVQ0TUtBUEVTWE9ESzZTRklDMEtWUjBTNC4u',
  'survey-pl':
    'https://forms.office.com/Pages/ResponsePage.aspx?id=BiSVVyivyEO03qTgb1dHbcz70sXClvBDkdTj5YDrB1NURVQ0TUtBUEVTWE9ESzZTRklDMEtWUjBTNC4u&lang=pl',
  'survey-de':
    'https://forms.office.com/Pages/ResponsePage.aspx?id=BiSVVyivyEO03qTgb1dHbcz70sXClvBDkdTj5YDrB1NURVQ0TUtBUEVTWE9ESzZTRklDMEtWUjBTNC4u&lang=de',
  'survey-en':
    'https://forms.office.com/Pages/ResponsePage.aspx?id=BiSVVyivyEO03qTgb1dHbcz70sXClvBDkdTj5YDrB1NURVQ0TUtBUEVTWE9ESzZTRklDMEtWUjBTNC4u&lang=en',
  'survey-nl':
    'https://forms.office.com/Pages/ResponsePage.aspx?id=BiSVVyivyEO03qTgb1dHbcz70sXClvBDkdTj5YDrB1NURVQ0TUtBUEVTWE9ESzZTRklDMEtWUjBTNC4u&lang=nl',
  'survey-fr':
    'https://forms.office.com/Pages/ResponsePage.aspx?id=BiSVVyivyEO03qTgb1dHbcz70sXClvBDkdTj5YDrB1NURVQ0TUtBUEVTWE9ESzZTRklDMEtWUjBTNC4u&lang=fr',
  'survey-be':
    'https://forms.office.com/Pages/ResponsePage.aspx?id=BiSVVyivyEO03qTgb1dHbcz70sXClvBDkdTj5YDrB1NURVQ0TUtBUEVTWE9ESzZTRklDMEtWUjBTNC4u&lang=nl'
}

export const RedirectPage = () => {
  const match = useRouteMatch<{link?: string}>('/go/:link')

  useEffect(() => {
    const link = links[match?.params?.link || '']
    if (link) {
      record('onBeforeShortenedLinkOpen', {shortenedLink: window.location.href, target: link})
      appInsights.flush(false)
      window.location = link
    }
  }, [match])

  return null
}
