const videosMetaData = [
  {
    country: 'AU',
    orgUnitIds: ['026'],
    languages: ['*'],
    videoLink:
      'https://hconnectdfstaticprod.z6.web.core.windows.net/onsite/video/Hanson-OnSite-App-AUS.mp4'
  },
  {
    country: 'US',
    orgUnitIds: ['*'],
    languages: ['*'],
    videoLink:
      'https://hconnectdfstaticprod.z6.web.core.windows.net/onsite/video/Hanson-OnSite-App-US.mov'
  },
  {
    country: 'CA',
    orgUnitIds: ['*'],
    languages: ['*'],
    videoLink:
      'https://hconnectdfstaticprod.z6.web.core.windows.net/onsite/video/Hanson-OnSite-App-US.mov'
  },
  {
    country: 'PL',
    orgUnitIds: ['*'],
    languages: ['*'],
    videoLink:
      'https://hconnectdfstaticprod.z6.web.core.windows.net/onsite/video/Hanson-OnSite-App-POL.mp4'
  },
  {
    country: 'DE',
    orgUnitIds: ['*'],
    languages: ['*'],
    videoLink:
      'https://hconnectdfstaticprod.z6.web.core.windows.net/onsite/video/OnSIte%20App%20Final.mp4'
  }
]

export const useOnSiteIntroVideo = (
  country: string,
  language: string,
  orgUnitId: string
): string | null => {
  const videoMetaData = videosMetaData.filter((metaData) => {
    {
      return (
        metaData.country === country &&
        ((metaData.languages.length === 1 && metaData.languages[0] === '*') ||
          metaData.languages.includes(language)) &&
        ((metaData.orgUnitIds.length === 1 && metaData.orgUnitIds[0] === '*') ||
          metaData.orgUnitIds.includes(orgUnitId))
      )
    }
  })

  if (videoMetaData[0]) {
    return videoMetaData[0].videoLink
  }
  return null
}
