import InvertColorsIcon from '@material-ui/icons/InvertColors'
import React from 'react'

import {useOnSiteTranslation} from '../../common/useOnSiteTranslation'
import {formatQuantity} from '../../common/utils'
import {OrderCardItem} from '../OrderCardItem'

interface OrderCardQuantityInformationProps {
  materialQuantity: number
  materialQuantityUom: string
  businessLine?: string
}

export const OrderCardQuantityInformation: React.FC<OrderCardQuantityInformationProps> = ({
  materialQuantity,
  materialQuantityUom
}) => {
  const {t} = useOnSiteTranslation()
  const orderQuantityInformation = `${t('order.quantityAbbreviation')} ${formatQuantity(
    materialQuantity
  )} ${materialQuantityUom}`
  return (
    <OrderCardItem
      key="quantity-unit-status"
      information={orderQuantityInformation}
      iconComponent={<InvertColorsIcon />}
    />
  )
}

export default OrderCardQuantityInformation
