import {OreoContextProvider} from '@hconnect/uikit/src/lib/Oreo/components/OreoContext'
import React from 'react'
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom'

import {OrderLayout} from './layouts/OrderLayout'
import {RedirectPage} from './pages/RedirectPage/RedirectPage'

const App: React.FC = () => (
  <OreoContextProvider>
    <Router>
      <Switch>
        <Route path="/go/:link">
          <RedirectPage />
        </Route>
        <Route path="/">
          <OrderLayout />
        </Route>
      </Switch>
    </Router>
  </OreoContextProvider>
)

export default App
