import {TruckLogo} from '@hconnect/uikit'
import {Box, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import moment from 'moment'
import React from 'react'

import type {Order, Supplement} from '../../common/interfaces'
import {useOnSiteTranslation} from '../../common/useOnSiteTranslation'
import {formatQuantity} from '../../common/utils'

const useStyle = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background,
    color: theme.palette.text.primary,
    marginTop: 28,
    marginLeft: 28
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 10
  },
  orderDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 42
  },
  orderDetailsBox: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 5,
    marginBottom: 5
  },
  truckIcon: {
    height: 26
  },
  orderInformation: {
    ...theme.typography.boldText,
    fontSize: 13,
    paddingLeft: 16
  },
  title: {
    ...theme.typography.lightText,
    fontSize: 13
  },
  value: {
    ...theme.typography.normalText
  }
}))

interface OrderInfoTabProps {
  order: Order
}

export const OrderInfoTab: React.FC<OrderInfoTabProps> = ({order}) => {
  const {t} = useOnSiteTranslation()
  const classes = useStyle()
  const {shippingDate, slumpValue, supplementItems, isTestBooked, orderNumber} = order

  const getExtras = (supplementItems: Supplement[]): React.ReactElement[] => {
    const extras = supplementItems.map((orderSupplement) => {
      const {itemName, quantity, quantityUom, relatedItemNumber} = orderSupplement
      return (
        <Typography
          className={classes.value}
          key={relatedItemNumber}
        >{`${itemName} ${formatQuantity(quantity)} ${quantityUom}`}</Typography>
      )
    })

    return Array.isArray(supplementItems) && supplementItems.length > 0
      ? extras
      : [
          <Typography className={classes.value} key={0}>
            {t('infoTab.noExtras')}
          </Typography>
        ]
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <img className={classes.truckIcon} src={TruckLogo} alt="" />
        <Typography className={classes.orderInformation}>
          {t('infoTab.orderInformation')}
        </Typography>
      </Box>
      <Box className={classes.orderDetails}>
        <Box className={classes.orderDetailsBox}>
          <Typography className={classes.title}>{t('infoTab.date')}</Typography>
          <Typography className={classes.value}>{moment(shippingDate).format('LLL')}</Typography>
        </Box>
        {!!slumpValue && (
          <Box className={classes.orderDetailsBox}>
            <Typography className={classes.title}>{t('infoTab.slump')}</Typography>
            <Typography className={classes.value}>{slumpValue}</Typography>
          </Box>
        )}
        {!!supplementItems && (
          <Box className={classes.orderDetailsBox}>
            <Typography className={classes.title}>{t('infoTab.extras')}</Typography>
            {getExtras(supplementItems)}
          </Box>
        )}
        {isTestBooked && (
          <Box className={classes.orderDetailsBox}>
            <Typography className={classes.title}>{t('infoTab.testingService')}</Typography>
            <Typography className={classes.value}>{t('infoTab.rmcBooked')}</Typography>
          </Box>
        )}
        <Box className={classes.orderDetailsBox}>
          <Typography className={classes.title}>{t('infoTab.orderNumber')}</Typography>
          <Typography className={classes.value}>{orderNumber}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
