export const Colors = {
  Black: 'black',
  Gray: '#e7e7e7',
  White: 'white',
  WhiteOff: '#fafafa',
  TealDark: '#00374d',
  TealDarkest: '#001922',
  TealMedium: '#7f9ba6',
  TealLight: '#b2c3c9',
  TealInky: '#003145',
  TealClear: 'rgba(0, 49, 69, 0.2)',
  Blue: '#29aaff',
  BlueClear: 'rgba(41, 170, 255, 0.1)',
  Azure: '#0277bd',
  Green: '#1ac463',
  GreenMedium: '#30c972',
  GreenDark: '#018237',
  GreenClear: 'rgba(1,130,55,0.15)',
  Mercury: '#e3e3e3',
  Jewel: '#128845',
  Yellow: '#ffaf00',
  YellowLight: '#ffe7b3',
  YellowMedium: '#ffb619',
  YellowInky: '#664600',
  YellowDark: '#b37a00',
  YellowClear: 'rgba(255,175,0,0.1)',
  Slate: '#5e6f9e',
  Cloudy: '#b4b9cd',
  Red: '#ff4f00',
  transparent: 'rgba(0,0,0,0)'
}

export const APP_STORE_LINK = 'https://apps.apple.com/us/app/onsite-by-hconnect/id1394075788?&ls=1'
export const GOOGLE_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=com.heidelbergcement.rmc.mycrete.us'
export const FALLBACK_LINK = 'https://hcag.app/r1'

export const APIOrderTypes = {
  APIOrderTypeReserved: 'reserved',
  APIOrderTypePending: 'pending',
  APIOrderTypeConfirmed: 'confirmed',
  APIOrderTypeCancelled: 'cancelled',
  APIOrderTypeInProgress: 'inProgress',
  APIOrderTypePartiallyShipped: 'partiallyShipped',
  APIOrderTypeShipped: 'shipped',
  APIOrderTypePartiallyDelivered: 'partiallyDelivered',
  APIOrderTypeDelivered: 'delivered',
  APIOrderTypePaymentPending: 'paymentPending'
}

export const OrderTypes = {
  OrderTypePending: 'OrderTypePending',
  OrderTypeReserved: 'OrderTypeReserved',
  OrderTypeConfirmed: 'OrderTypeConfirmed',
  OrderTypeCancelled: 'OrderTypeCancelled',
  OrderTypeActive: 'OrderTypeActive',
  OrderTypeCompleted: 'OrderTypeCompleted',
  OrderTypeOnHold: 'OrderTypeOnHold',
  OrderTypePaymentPending: 'OrderTypePaymentPending'
}

export const ActiveOrderStates = {
  ActiveOrderStateETAAvailable: 'ActiveOrderStateETAAvailable',
  ActiveOrderStateAllTrucksArrived: 'ActiveOrderStateAllTrucksArrived',
  ActiveOrderStateETANotAvailable: 'ActiveOrderStateETANotAvailable' // Fallback
}

export const businessLines = {
  RMC: 'RMC',
  AGG: 'AGG',
  CEM: 'CEM'
}

export const Stages = {
  PROD: 'PROD',
  UAT: 'UAT',
  QA: 'QA',
  E2E: 'E2E'
}

export const orderShippingTypes = {
  collect: 'collect',
  deliver: 'deliver'
}

export const RobotoFontFamilyName = 'Roboto'

export const Fonts = {
  RobotoLight: {fontFamily: RobotoFontFamilyName, fontWeight: '300'},
  RobotoRegular: {fontFamily: RobotoFontFamilyName, fontWeight: '400'},
  RobotoMedium: {fontFamily: RobotoFontFamilyName, fontWeight: '500'},
  RobotoBold: {fontFamily: RobotoFontFamilyName, fontWeight: '800'}
}

export const getEuMembers = (): string[] => [
  'BE',
  'BG',
  'CZ',
  'DK',
  'DE',
  'EE',
  'IE',
  'EL',
  'ES',
  'FR',
  'HR',
  'IT',
  'CY',
  'LV',
  'LT',
  'LU',
  'HU',
  'MT',
  'NL',
  'AT',
  'PL',
  'PT',
  'RO',
  'SI',
  'SK',
  'FI',
  'SE',
  'GB'
]
