import React from 'react'
import {StyleSheet, Text, View} from 'react-native-web'

import {Colors, Fonts} from '../../common/constants'

const styles = StyleSheet.create({
  deliveryInformation: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 16,
    marginVertical: 8
  },
  iconContainer: {
    width: 26
  },
  textContainer: {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
    paddingRight: 16
  },
  information: {
    textOverflow: 'clip',
    color: Colors.TealDark,
    ...Fonts.RobotoRegular,
    fontSize: 16,
    marginLeft: 12
  },
  extras: {
    color: Colors.TealInky,
    ...Fonts.RobotoBold,
    fontSize: 16,
    marginLeft: 15
  }
})

interface OrderCardItemProps {
  information: string
  extras?: string
  iconComponent?: React.ReactChild
  testIDIcon?: string
  testIDText?: string
}

export const OrderCardItem: React.FC<OrderCardItemProps> = ({
  information,
  extras,
  iconComponent,
  testIDIcon,
  testIDText
}) => {
  return (
    <View style={styles.deliveryInformation}>
      {!!iconComponent && (
        <View style={styles.iconContainer} testID={testIDIcon}>
          {iconComponent}
        </View>
      )}

      <View style={styles.textContainer}>
        <Text style={styles.information} numberOfLines={5} ellipsizeMode="tail" testID={testIDText}>
          {information}
        </Text>
        {extras && <Text style={styles.extras}>{extras}</Text>}
      </View>
    </View>
  )
}
