import {Button, ButtonProps} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

const useStyle = makeStyles((theme) => ({
  root: {
    height: 32,
    color: 'white',
    // borderColor: theme.palette.colors.White,
    borderColor: 'white',
    borderRadius: 18,
    fontSize: 12,
    fontFamily: 'Roboto',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'rgba(41, 170, 255, .2)'
    }
  }
}))

interface MobileHeaderButtonProps {
  props?: any
  caption?: string
}

export const MobileHeaderButton: React.FC<ButtonProps & MobileHeaderButtonProps> = ({
  caption,
  ...props
}) => {
  const classes = useStyle()
  return (
    <Button variant="outlined" disableRipple className={classes.root} {...props}>
      {caption}
    </Button>
  )
}
