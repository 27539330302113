import React from 'react'

import {useOnSiteTranslation} from '../../common/useOnSiteTranslation'
import {OrderOverviewTab} from '../OrderOverviewTab'

interface OrderMapTabProps {
  screenshot: string
}

export const OrderMapTab: React.FC<OrderMapTabProps> = ({screenshot}) => {
  const {t} = useOnSiteTranslation()
  return <OrderOverviewTab description={t('mapTab.description')} screenshot={screenshot} />
}
