/* eslint-disable jsx-a11y/media-has-caption */
import {Box, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React, {useRef, useState} from 'react'
import PageVisibility from 'react-page-visibility'

import {record} from '../../common/analytics'
import type {Order} from '../../common/interfaces'
import {useOnSiteIntroVideo} from '../../common/useOnSiteIntroVideo'
import {useOnSiteTranslation} from '../../common/useOnSiteTranslation'
import {getUrlParameterValue} from '../../common/utils'

const useStyle = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background,
    color: theme.palette.text.primary,
    marginTop: 28,
    marginLeft: 10,
    marginRight: 10
  },
  video: {
    maxWidth: '100%',
    height: 'auto'
  },
  textContainer: {
    marginBottom: 28
  },
  titleText: {
    ...theme.typography.boldText,
    textAlign: 'center'
  },
  descriptionText: {
    ...theme.typography.normalText,
    textAlign: 'center'
  }
}))

interface OrderTeaserVideoProps {
  order: Order
}

export const OrderTeaserVideo: React.FC<OrderTeaserVideoProps> = ({order}) => {
  const {t, i18n} = useOnSiteTranslation()
  const classes = useStyle()
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false)
  const countryCode = getUrlParameterValue('countryCode')
  const locale = i18n.language
  const [language] = locale.split('_')
  const linkTeaserVideo = useOnSiteIntroVideo(countryCode, language.toUpperCase(), order.orgUnitId)

  const eventDetectionTimer = useRef<number | null>(0)
  const eventsBuffer = useRef<string[]>([])

  const detectEvent = () => {
    if (eventDetectionTimer.current !== null) {
      clearTimeout(eventDetectionTimer.current)
    }
    eventDetectionTimer.current = window.setTimeout(() => {
      const events = Array.from(new Set(eventsBuffer.current))
      const eventCount = events.length
      const isSeekedEvent = events.includes('Seeked')
      const isPlayEvent = events.includes('Play')
      const isPauseEvent = events.includes('Pause')
      const isEndEvent = events.includes('End')

      const eventName = (() => {
        if (eventCount === 1) {
          const [eventName] = events
          return eventName
        }
        if (isEndEvent) {
          return 'End'
        }
        if (isSeekedEvent && isPauseEvent) {
          return 'Seeked'
        }
        if (isSeekedEvent && isPlayEvent) {
          return 'Play'
        }
      })()

      if (eventName && linkTeaserVideo) {
        const {
          orderId,
          shippingAddress: {siteId, country},
          customer: {customerId},
          orgUnitId,
          orderStatus
        } = order
        const eventData = {
          orderId,
          siteId,
          customerId,
          country,
          orgUnitId,
          orderStatus
        }
        record(
          `previewOnTeaserVideo${eventName}`,
          {...eventData, videoLink: linkTeaserVideo},
          order
        )
      }
      eventsBuffer.current = []
      if (eventDetectionTimer.current !== null) {
        clearTimeout(eventDetectionTimer.current)
      }
      eventDetectionTimer.current = null
    }, 300)
  }

  const onPlay = () => {
    eventsBuffer.current.push('Play')
    detectEvent()
  }
  const onPaused = () => {
    eventsBuffer.current.push('Pause')
    detectEvent()
  }
  const onEnd = () => {
    eventsBuffer.current.push('End')
    detectEvent()
  }
  const onSeeked = () => {
    eventsBuffer.current.push('Seeked')
    detectEvent()
  }

  if (!linkTeaserVideo) {
    return null
  }

  const videoElement = document.getElementById('teaserVideo')

  const handleVisibilityChange = (isVisible: boolean): void => {
    if (videoElement && videoElement instanceof HTMLVideoElement) {
      if (isVisible) {
        if (isVideoPlaying) {
          videoElement.play()
        }
      } else {
        setIsVideoPlaying(!videoElement.paused)
        videoElement.pause()
      }
    }
  }

  const isNativeIOS =
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

  return (
    <Box className={classes.container}>
      <Box className={classes.textContainer}>
        <Typography className={classes.titleText}>{t('mediaContent.title')}</Typography>
        <Typography className={classes.descriptionText}>{t('mediaContent.description')}</Typography>
      </Box>

      <video
        onPlay={onPlay}
        onPause={onPaused}
        onSeeked={onSeeked}
        onEnded={onEnd}
        id="teaserVideo"
        className={classes.video}
        controls
        autoPlay={isNativeIOS}
        preload="metadata"
        src={linkTeaserVideo}
      />
      <PageVisibility onChange={handleVisibilityChange} />
    </Box>
  )
}
